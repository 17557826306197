import React, { Component } from "react";
import axios from "axios";
import Modal from "react-awesome-modal";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      emailHeader: "Sorry!",
      emailBody:
        "There was a problem. Email us directly at info@kdypro.com or give us a call!",
      modalVisible: false
    };
  }

  emailResponse(emailHeader, emailBody) {
    this.setState({ emailHeader: emailHeader, emailBody: emailBody });
  }

  openModal() {
    this.setState({
      modalVisible: true
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false
    });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // get our form data out of state
    const { message, phone, email, name } = this.state;

    axios
      .post("https://www.enformed.io/ghnyxjd4/", {
        message,
        phone,
        email,
        name
      })
      .then(response => {
        console.log(response);
        this.emailResponse("Email sent!", "We will get back to you shortly");
        this.resetForm();
        this.openModal();
      })
      .catch(error => {
        console.log(error);
        this.emailResponse(
          "There was a problem!",
          "Email directly at info@kdypro.com or give us a call!"
        );
        this.resetForm();
        this.openModal();
      });
  };

  resetForm() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      message: ""
    });
  }

  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onInfoWindowClose(props, marker, e) {
    this.setState({
      selectedPlace: {},
      activeMarker: {},
      showingInfoWindow: false
    });
  }

  render() {
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    return (
      <div className="container-fluid phone">
        <div className="col-md-6">
          <div className="panel panel-phone">
            <div className="panel-heading">
              <div className="panel-title text-center">
                For a quick reply, give us a call
              </div>
            </div>
            <div className="panel-body text-center">
              <h1>(438) 998-0466</h1>
            </div>
          </div>

          <form
            className="form-horizontal"
            id="contact_form"
            onSubmit={this.onSubmit}
          >
            <fieldset>
              <legend>email: info@kdypro.com</legend>
              <div className="form-group">
                <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <div className="buffer">
                        <i className="fas fa-user" />
                        <p className="addon-text">Name</p>
                      </div>
                    </span>
                    <input
                      name="name"
                      placeholder="Name"
                      className="form-control"
                      type="text"
                      onChange={this.onChange}
                      value={this.state.name}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <div className="buffer">
                        <i className="fas fa-at" />
                        <p className="addon-text">E-mail</p>
                      </div>
                    </span>
                    <input
                      name="email"
                      placeholder="E-Mail Address"
                      className="form-control"
                      type="text"
                      onChange={this.onChange}
                      value={this.state.email}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <div className="buffer">
                        <i className="fas fa-phone" />
                        <p className="addon-text">Phone</p>
                      </div>
                    </span>
                    <input
                      name="phone"
                      placeholder="(555) 555-5555"
                      className="form-control"
                      type="text"
                      onChange={this.onChange}
                      value={this.state.phone}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    <span className="input-group-addon">
                      <div className="buffer">
                        <i className="fas fa-pencil-alt" />
                        <p className="addon-text">Message</p>
                      </div>
                    </span>
                    <textarea
                      className="form-control message"
                      name="message"
                      placeholder="Project Description"
                      onChange={this.onChange}
                      value={this.state.message}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-md-12">
                  <button className="btn btn-primary">
                    <span className="btn-content">send</span>
                    <span className="icon">
                      <i className="fas fa-paper-plane" />
                    </span>
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="col-md-6">
          <div className="mapTitle">
            <h1>
              5845 Decarie Blv <span>- Montreal, QC H3W3C9</span>
            </h1>
          </div>
          <div className="mapContainer">
            <Map
              className={"map"}
              style={{
                width: "100%",
                height: "100%",
                position: "relative"
              }}
              initialCenter={{
                lat: 45.48866,
                lng: -73.63943
              }}
              google={this.props.google}
              zoom={14}
            >
              <Marker
                title={"KDY Productions Office"}
                name={"KDY"}
                position={{ lat: 45.48866, lng: -73.63943 }}
              />
            </Map>
          </div>
        </div>
        <Modal
          visible={this.state.modalVisible}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div>
            <h2 className="emailHeader">{this.state.emailHeader}</h2>
            <h3 className="emailBody">{this.state.emailBody}</h3>
            <button
              className="btn btn-primary emailModalButton"
              onClick={() => this.closeModal()}
            >
              close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDrcADR5-3T5Wch2MyKOnygmcxzeU9nR70"
})(Contact);
